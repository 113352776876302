import React from "react";
import Layout from "../component/layout";
import MVPProcessComponent from "../component/Services/mvpProcessComponent";
import { graphql } from 'gatsby';
import FooterComponent from "../component/footerComponent";
import ServicesHomeComponent from "../component/Services/servicesHomeComponent";
import ServicesImage from '../images/services_banner-min.jpg'

const BuildMVP = (props) =>{
    const {
        data:{
            wpPost:{
                seo,
                serviciesHeader: {
                  servicesHomeTitle,
                  servicesHomeDescription,
                },
                buildMvpSection :{
                    buildMvpSectionDescription,
                    buildMvpSectionTitle,
                    designeAndPropertyDescription,
                    designeAndPropertyTitle,
                    implemantationDescription,
                    implemantationTitle,
                    productIdeaDescription,
                    productIdeaTitle,
                    supportDescription,
                    supportTitle,
                    buildMvpSectionImage :{
                      localFile :{
                        childImageSharp :{
                          gatsbyImageData:buildMvpSectionImage
                        },
                      },
                    },
                    designeAndPropertyImage :{
                      localFile :{
                        childImageSharp :{
                          gatsbyImageData:designeAndPropertyImage
                        },
                      },
                    },
                    implemantationImage :{
                      localFile :{
                        childImageSharp :{
                          gatsbyImageData:implemantationImage
                        },
                      },
                    },
                    productIdeaImage :{
                      localFile :{
                        childImageSharp :{
                          gatsbyImageData:productIdeaImage
                        },
                      },
                    },
                    supportImage :{
                      localFile :{
                        childImageSharp :{
                          gatsbyImageData:supportImage
                        },
                      },
                    },
                  },
            },
        },
        pageContext: { title },
    } = props;
    return(
        <Layout seo={seo} title={title}>
            {/* <ServicesHomeComponent
             servicesBackground={ServicesImage}
             servicesHomeTitle={servicesHomeTitle}
             servicesDescription={servicesHomeDescription}
             isMVP={true}
             /> */}
        <MVPProcessComponent
        MVPTitle={buildMvpSectionTitle}
        MVPDescription={buildMvpSectionDescription}
        MVPBuildImage={buildMvpSectionImage}
        />
         <MVPProcessComponent
        MVPTitle={productIdeaTitle}
        MVPDescription={productIdeaDescription}
        MVPBuildImage={productIdeaImage}
        isLeft={true}
        isTesting={true}
        />
         <MVPProcessComponent
        MVPTitle={designeAndPropertyTitle}
        MVPDescription={designeAndPropertyDescription}
        MVPBuildImage={designeAndPropertyImage}
        />
         <MVPProcessComponent
        MVPTitle={implemantationTitle}
        MVPDescription={implemantationDescription}
        MVPBuildImage={implemantationImage}
        isLeft={true}
        />
         <MVPProcessComponent
        MVPTitle={supportTitle}
        MVPDescription={supportDescription}
        MVPBuildImage={supportImage}
        isEnd={true}
        />
        <FooterComponent/>
        </Layout>
    )
}
export const query = graphql`
  query {
    wpPost(slug: {eq: "build-saas-mvp-page"}) {
      seo {
        metaDesc
        title
      }
      serviciesHeader {
        servicesHomeTitle
        servicesHomeDescription
      }
      buildMvpSection {
        buildMvpSectionDescription
        buildMvpSectionTitle
        designeAndPropertyDescription
        designeAndPropertyTitle
        implemantationDescription
        implemantationTitle
        productIdeaDescription
        productIdeaTitle
        supportDescription
        supportTitle
        buildMvpSectionImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        designeAndPropertyImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        implemantationImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        productIdeaImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        supportImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
}
`;
export default BuildMVP